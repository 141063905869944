import axios from 'axios'



const Config = (auth) => {

    // const accessToken = useSelector((state) => state.global.accessToken);
    const Instance = axios.create({
        // baseURL: "http://localhost:80",
        // baseURL: "https://back2back-rabvfmzrtq-od.a.run.app/", BACKOFFICE TEST
        baseURL : process.env.REACT_APP_BACKEND_URL, //BACKOFFICE LIVE
        
        withCredentials: true, 
        headers: {
            // Authorization: `Bearer ${accessToken}`
        }

    })
        
    return Instance
};

export default Config;